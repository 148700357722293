import { Pipe, PipeTransform } from '@angular/core';
import { CURRENCY_LIST } from '../constants';

@Pipe({
  name: 'currencyShortName',
  pure: false,
  standalone: true,
})
export class CurrencyShortNamePipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    return CURRENCY_LIST.filter((item) => item.currency === value)[0]?.shortName || value;
  }
}
